import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import request from "../http.js";
import { ElNotification } from "element-plus";
import { ref, onMounted } from "vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import router from "../router/index.js";
export default {
  __name: 'UserHome',
  setup(__props) {
    const userInfo = ref({
      "nickName": "请登录"
    });
    async function getUserInfo() {
      var result = await request.fetchGet("/oauth/wechat/info", {});
      console.log(" 用户信息====", result.data);
      if (result && result.data && result.data.success) {
        console.log("用户信息========", result.data.data);
        userInfo.value = result.data.data;
      }
    }
    //弹窗提示
    function popMessage(title, content, type, time) {
      if (type == true) {
        type = "success";
      } else {
        type = "fail";
      }
      if (time == null || time < 1) {
        time = 10;
      }
      ElNotification({
        title: title,
        message: content,
        type: type,
        duration: time
      });
    }
    function getUrlQuery() {
      let query = router.currentRoute.value.query;
      console.log("请求携带参数", query);
      return query;
    }
    //本地记录缓存
    function saveCache(key, value) {
      if (!value) {
        popMessage("设置有误", key + "名字不存在", false, 1100);
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
        console.log("设置本地缓存:" + key + ",value:" + JSON.stringify(value));
      }
      if (key == "my" || key == "you" || key == "phone") {
        popMessage("设置完成", key + "为:" + JSON.stringify(value), true, 1900);
      }
    }
    function getCache(key) {
      console.log(key, "=====", JSON.parse(window.localStorage.getItem(key)));
      return JSON.parse(window.localStorage.getItem(key));
    }
    async function startLogin() {
      let query = getUrlQuery();
      let token = query.accessToken;
      let localToken = getCache("accessToken");
      console.log("token====", token);
      if (token) {
        //不考虑本地是否存在，直接设置新的
        saveCache("accessToken", token);
      }
      if (localToken || token) {
        //完成后展示名字文案
        getUserInfo();
      }
    }
    onMounted(() => {
      console.log("页面开始初始化");
      //根据 url 拼接参数，开始获取街区 token 进行使用
      startLogin();
    });
    return (_ctx, _cache) => {
      const _component_el_text = _resolveComponent("el-text");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createBlock(_component_el_row, {
        gutter: 8
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, null, {
          default: _withCtx(() => [_createVNode(_component_el_text, {
            class: "mx-1",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("Hi ====" + _toDisplayString(userInfo.value.nickName), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, null, {
          default: _withCtx(() => [_createVNode(_component_el_text, {
            class: "mx-1",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("推送开关 ====" + _toDisplayString(userInfo.value.push == 1 ? "开启" : "关闭"), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, null, {
          default: _withCtx(() => [_createVNode(_component_el_text, {
            class: "mx-1",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("用户状态 ====" + _toDisplayString(userInfo.value.status == 1 ? '已激活' : userInfo.value.status == 2 ? '已禁用' : userInfo.value.status == 4 ? '未激活' : userInfo.value.status == 5 ? "退出企业" : "未定义"), 1)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};